import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../context/authcontext";

// views - main
import Main from "../views.xz/main";

// // views - home
import HomeUser from "../views.home/home-user";


// views - accounts
import Accounts from "../views.accounts/accounts";
import AccountsNew from "../views.accounts/accounts-new";
import AccountsLinkInrxBank from "../views.accounts/accounts-link-inrx-bank";
import AccountsCreateInrxVirtual from "../views.accounts/accounts-create-inrx-virtual";

import AccountsCreateBinance from "../views.accounts/accounts-create-web3-bnb";

// views - network
import NetworkList from "../views.network/network-list";

// views - transfers
import TransfersList from "../views.transfers/transfers-list";
import TransfersCredit from "../views.transfers/transfers-credit";

// views - user
import UserDetails from "../views.user/user-details";


// // views - auth
import AuthMailCode from "../views.auth/auth-mail-code";
// import AuthMailCheckFirebase from "../views-auth/auth-mail-check";
import AuthNext from "../views.auth/auth-next";
import AuthSessionX from "../views.auth/auth-session-x";

import UserOnboard from "../views.auth/user-onboard";
// import BusinessOnboard from "../views-onboard/business-onboard";


const routes = [

  { route:'/', content: <Main />, auth:false },

  // user
  { route:'/user/home', content: <HomeUser />, auth:false },

  // assets
  { route:'/user/accounts', content: <Accounts />, auth:false },
  { route:'/user/accounts/new', content: <AccountsNew />, auth:false },
  { route:'/user/accounts/link/bank', content: <AccountsLinkInrxBank />, auth:false },
  { route:'/user/accounts/create/virtual', content: <AccountsCreateInrxVirtual />, auth:false },
  
  { route:'/user/accounts/create/binance', content: <AccountsCreateBinance />, auth:false },

  // transfers
  { route:'/user/network', content: <NetworkList />, auth:false },
  

  // transfers
  { route:'/user/transfers', content: <TransfersList />, auth:false },
  { route:'/user/transfers/credit', content: <TransfersCredit />, auth:false },


  // user
  { route:'/user/account', content: <UserDetails />, auth:false },

  // auth
  { route:'/auth', content: <AuthMailCode />, auth: false },
  // { route:'/auth/check', content: <AuthMailCheckFirebase />, auth: false },
  { route:'/auth/next', content: <AuthNext />, auth: true },
  { route:'/auth/x', content: <AuthSessionX />, auth: true },


  { route:'/user/onboard', content: <UserOnboard />, auth: false },
  // { route:'/business/onboard', content: <BusinessOnboard />, auth: true },

]


export default function RouteX() {

  const { user } = useAuth();
  // console.log (user)

  return (
    <Routes>
      {routes.map ((item,i)=>(item.auth
        ? <Route key={i} path={item.route} element={!user ? <Navigate to='/' replace /> : item.content} />
        : <Route key={i} path={item.route} element={item.content} />
      ))}
    </Routes>
  );
}