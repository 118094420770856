// auth - firebase mail link
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbDividerMedium from "../webx/webb-divider-md";

import { GetLocalUser } from "../../services/srvc-auth-user";

import { AccountsList } from "../../services/srvc-accounts-realm";

export default function AccountsListModule () {

  const navigate = useNavigate();
  const asset = GetLocalUser()
  
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState()


  useEffect( () => {
    if (asset){

      const fetchData = async() => {
        setLoading(true);

        const result = await AccountsList({data: {
          user: asset.item, index: 1, items: 25, 
          filters:{ name: {} }
        }})
        // console.log (result)

        if (result.data) setData(result.data.list)

        setLoading(false);
      }
      fetchData()
    } else {}
  },[]);


  if (loading) return <></>
  if (!loading && data && data.length === 0) return <>No Accounts Linked</>

  return (
  <>


    <p className="text-bold">Accounts Linked</p>

    <div className="back-color-wite rounded-none border">
    {data && data.map((item, i) => (
      <>
      <div className="d-flex px-3 mt-3" key={i}>
          
        <div className="me-auto">         
          <p className="m-0 text-sm text-primary">
            <span className="">{item.name}</span>
          </p>
        </div>
    
        <div className="text-end">         
          <p className="m-0">
            <span className="ms-1 text-color-tone text-uppercase">{item.form}</span>
          </p>
        </div>

      </div>
      <div className="px-3 mb-3">
        <p className="text-small m-0">{item.account}</p>
        <p className="text-small text-color-tone m-0">{}</p>
      </div>
      <div className="border-bottom"></div></>
    ))}
    </div> 

  </>

  )
}